import React, { SyntheticEvent } from 'react';

import DeliveryPanel from '~/src/common/components/GridCard/DeliveryPanel/DeliveryPanel';
import Icon from '~/src/common/components/Icon';
import Link from '~/src/common/components/Link';
import { useDelivery } from '~/src/common/hooks/use-delivery';
import { useStable } from '~/src/common/hooks/use-stable';
import I18n from '~/src/common/services/I18n';
import { showNiceModal } from '~/src/common/services/ModalsManager';
import { Cart } from '~/src/common/typings/cart';
import { ImageFormatIds, ImageParams } from '~/src/common/typings/image';
import { RecipeItem } from '~/src/common/typings/product';
import { getImageUrlFromFormat } from '~/src/common/utils/cloudinary';
import { useGetCart } from '~/src/queries/api-ecom/generated/api-ecom';
import { isDelivery } from '~/src/screens/App/CatalogLayout/utils';
import { useRecipeLink } from '~/src/screens/CatalogContent/ContentScreen/hooks';

import * as GS from '../layout';

import * as S from './layout';

interface Props {
  recipe: RecipeItem;
  eventSource: string;
  categoryName?: string;
  subcategory?: { id: string; name: string };
  isDeliveryPanelOpen: boolean;
  onDeliveryPanelOpen: (recipeId: string | null) => void;
  onClick?: (recipe: RecipeItem) => void;
}

const RecipeCard = ({
  recipe,
  categoryName,
  subcategory,
  eventSource,
  isDeliveryPanelOpen,
  onDeliveryPanelOpen,
  onClick,
}: Props) => {
  const { data: cart } = useGetCart<Cart>();
  const { name, images, duration, cookTypes, slug, id } = recipe;
  const imageUrl = getImageUrlFromFormat(
    images[0],
    ImageFormatIds.CardRatio,
    ImageParams.listingCard,
  );

  const { as, query } = useRecipeLink(slug, {
    category: categoryName,
    subcategory: subcategory?.name,
    eventSource,
  });

  const openIngredientsModal = useStable(() => {
    const args = { slug, eventSource, categoryName, subcategory };
    // eslint-disable-next-line @typescript-eslint/no-floating-promises  -- auto-ignored when updating eslint
    showNiceModal('recipe-ingredients-modal', args);
  });

  const delivery = useDelivery(cart);

  const handleClickButton = () => {
    if (delivery.isSetup && delivery.isTimeSlotValid) {
      openIngredientsModal();
    } else {
      onDeliveryPanelOpen(id);
    }
  };

  return (
    <GS.Container onClick={() => onClick?.(recipe)}>
      <GS.Image src={imageUrl} $objectFit="cover" $objectPosition="top center" alt={name} />

      {cookTypes[0] ? <S.CookTypeTag>{cookTypes[0]}</S.CookTypeTag> : null}

      <GS.Content>
        <Link href={{ pathname: as, query }} as={as}>
          <GS.TopSectionContainer>
            <GS.InformationContainer>
              <S.ProductName>{name}</S.ProductName>
            </GS.InformationContainer>
          </GS.TopSectionContainer>
        </Link>
        <GS.BottomSectionContainer
          // Prévient l'ouverture de la page recette
          onClick={(e: SyntheticEvent) => {
            e.stopPropagation();
          }}>
          <S.DurationBlock>
            <Icon name="clock-normal" size={16} color="PRIMARY" />
            <p>{I18n.t('recipe-card.duration', { duration })}</p>
          </S.DurationBlock>
          <S.PickerButton icon="list-plus" color="WHITE" onClick={handleClickButton} />
        </GS.BottomSectionContainer>
      </GS.Content>
      <DeliveryPanel
        hasAddress={delivery.address != null}
        isDelivery={isDelivery(delivery)}
        isOpen={isDeliveryPanelOpen}
        onOpen={onDeliveryPanelOpen}
        onUpdateDelivery={() => openIngredientsModal()}
      />
    </GS.Container>
  );
};

export default React.memo(RecipeCard);
