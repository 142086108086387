import styled from 'styled-components';

import Button from '~/src/common/components/Button';
import {
  productCardTagStyle,
  ProductName as BaseProductName,
} from '~/src/common/components/GridCard/layout';

export const CookTypeTag = styled.div`
  ${productCardTagStyle}

  color: ${({ theme }) => theme.palette.common.PRIMARY};
  background-color: ${({ theme }) => theme.palette.common.LIGHT_GREEN};
`;

export const DurationBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 4px;
  border-radius: 4px;

  background-color: ${({ theme }) => theme.palette.common.LIGHT_GREEN};

  & > p:last-child {
    margin: 0 0 0 4px;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    text-align: center;
    color: ${({ theme }) => theme.palette.common.PRIMARY};
  }
`;

export const PickerButton = styled(Button)`
  width: 40px;
  min-width: unset;
  height: 40px;
  padding-right: 0;
  padding-left: 0;

  & > div > span {
    line-height: 24px;
    font-size: 24px;
  }
`;

export const ProductName = styled(BaseProductName)`
  margin-bottom: 0;
`;
